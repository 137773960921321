@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../../../../styles/sass/utilities/variables';
@import '../../../../../styles/sass/utilities/functions';
@import '../../../../../styles/sass/utilities/mixins';

.header {
  background: $primary-blue;
  height: 64px;
  padding: 0px 0px 0px 48px;
  gap: 31px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xl) {
    padding-left: calc((100vw - 1440px) / 2 + 48px);
  }

  img {
    width: 90.125px;
  }
}

.loginPageWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  opacity: 0.9;

  @include media-breakpoint-down(sm) {
    overflow-wrap: break-word;
  }

  .banner-container {
    display: flex;
    height: 686px;
    padding: 120px calc((100vw - 1440px) / 2 + 120px);
    justify-content: flex-end;
    flex-direction: column;
    background-image: url('https://alcon.widen.net/content/m7twkp1qhe/webp/Hero_Landing_DSB.webp?position=c&quality=80&u=uxrrnh&use=n2zyv');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @include media-breakpoint-down(xl) {
      padding: 120px;
    }

    @include media-breakpoint-down(lg) {
      background-image: url('https://alcon.widen.net/content/ixnbypiaj3/webp/Hero_Landing_DSS.webp?position=c&quality=80&u=uxrrnh&use=ghamy');
    }

    @include media-breakpoint-down(md) {
      padding: 96px 24px;
      height: auto;
      justify-content: start;
      background-image: url('https://alcon.widen.net/content/lbs4wekmnf/webp/Hero_Landing_TB.webp?position=c&quality=80&u=uxrrnh&use=xfmrj');
    }

    @include media-breakpoint-down(sm) {
      padding: 64px 16px;
      height: auto;
      background-image: url('https://alcon.widen.net/content/xe8d1us4sj/webp/Hero_Landing_MB.webp?position=c&quality=80&u=uxrrnh&use=d2lhz');
    }

    @include media-breakpoint-down(xs) {
      background-size: auto 100%;
    }

    .banner-content {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        width: 687px;
      }

      img {
        width: 206px;
        height: 64px;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: flex-start;
    margin-top: 48px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .register-button:active {
      color: $secondary-blue;
      border: 2px solid $primary-white;
      background: $primary-white;
    }

    .login-button {
      @include alcon-button;
      @include fontStyle($open-sans, $font-normal, 600, 16px, $white);
      line-height: 22.4px;
      letter-spacing: 0.15px;
      height: 56px;
      width: fit-content;
      padding: 16px 40px 16px 40px;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  adi-footer {
    margin-top: auto;
  }
}

h1 {
  text-align: left;
  margin: 48px 0 0;
  line-height: 58.2px;
  @include fontStyle($open-sans, $font-normal, 300, 56px, $primary-white);
  width: 687px;

  @include media-breakpoint-down(md) {
    height: auto;
    width: auto;
    line-height: 105%;
    font-size: 48px;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
    width: auto;
  }
}

.register-button {
  @include alcon-button-outlined;
  @include fontStyle($open-sans, $font-normal, 700, 16px, $secondary-blue);
  line-height: 24px;
  letter-spacing: 0.15px;
  height: 56px;
  padding: 16px 40px 16px 40px;
  max-width: 280px;
  display: flex;
  justify-content: center;
  width: fit-content !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
    height: auto;
    max-width: none;
  }
}

.overlay-text-subtitle {
  text-align: left;
  margin: 24px 0 0;
  line-height: 130%;
  @include fontStyle($open-sans, $font-normal, 400, 24px, $primary-white);
}

.login-button:disabled {
  background-color: $primary-dark-white;
  color: $primary-light-grey;
}

.register-button:disabled {
  background-color: $primary-dark-white;
  color: $primary-light-grey;
}

.container1 {
  display: flex;
  flex-direction: row;
  gap: 24px;
  background: $primary-white;
  padding: 64px calc((100vw - 1440px) / 2 + 120px);

  &__title {
    @include fontStyle($open-sans, $font-normal, 400, 40px, $primary-blue);
    line-height: 105%;
  }

  &__subtitle {
    @include fontStyle($open-sans, $font-normal, 400, 18px, $light-grey);
    line-height: 130%;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      width: 60%;
      padding-right: 24px;
    }
  }

  .imageSection {
    display: flex;
    width: 60%;

    iframe,
    video {
      width: -webkit-fill-available;
      border-radius: 16px;

      @include media-breakpoint-up(lg) {
        width: 657px;
        height: 371px;
      }
    }
  }

  .btn-container {
    display: none;
    gap: 16px;
    margin-top: 16px;

    @include media-breakpoint-down(sm) {
      display: none;
      flex-direction: column;
    }

    .request-btn {
      margin: 0;
    }

    .button {
      border: 2px solid $primary-blue;
      color: $primary-blue;
    }
  }

  @include media-breakpoint-down(xl) {
    padding: 64px 120px;
  }

  @include media-breakpoint-down(lg) {
    gap: 40px;
    flex-direction: column;

    &__content {
      padding-right: 24px;
      width: auto;
    }

    .imageSection {
      width: auto;
      order: -1;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 64px 24px;

    &__subtitle {
      width: 100%;
      line-height: 130%;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 64px 16px 0;
    gap: 0;

    &__content {
      padding: 40px 24px 48px 0;
    }

    .imageSection {

      iframe,
      video {
        border-radius: 8px;
      }
    }
  }
}

.business-section {
  display: flex;
  padding: 48px calc((100vw - 1440px) / 2 + 120px) 64px;
  background: $primary-white;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  @include media-breakpoint-down(xl) {
    padding: 48px 120px 64px;
  }

  @include media-breakpoint-down(md) {
    padding: 0 24px 64px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 16px;
  }

  .text {
    padding: 24px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 32px;
    line-height: 105%;
    @include fontStyle($open-sans, $font-normal, 300, 48px, $primary-blue);

    @include media-breakpoint-down(md) {
      padding: 24px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 24px;
    }

    .op {
      @include media-breakpoint-down(sm) {
        white-space: nowrap;
      }
    }

    p {
      @include fontStyle($open-sans, $font-normal, 400, 18px, $light-grey);
      line-height: 130%;
      margin: 0;
    }
  }

  .card-section {
    display: flex;
    gap: 32px;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }

    .card {
      @include adi-home-card();

      .card-image {
        border-radius: 8px;

        img {
          width: 100%;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }

      .card-body {

        padding: 48px 32px;
        text-align: center;

        @include media-breakpoint-down(md) {
          padding: 48px 24px;
        }

        h3 {
          @include fontStyle($open-sans,
            $font-normal,
            600,
            32px,
            $primary-blue);
          line-height: 140%;
          margin: 0;
          height: 78px;
        }

        h4 {
          @include fontStyle($open-sans,
            $font-normal,
            600,
            28px,
            $primary-blue);
          line-height: 140%;
          margin: 0;
        }

        p {
          @include fontStyle($open-sans, $font-normal, 400, 16px, $light-grey);
          line-height: 140%;
          height: auto;
        }

        .card-link {
          @include fontStyle($open-sans,
            $font-normal,
            600,
            16px,
            $primary-blue);
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
            padding: 5px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 40px;
            border: 1px solid $primary-blue;
            margin-right: 5px;
          }

          .text-border {
            border-bottom: 1.5px solid $primary-blue;
          }
        }
      }
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    margin: 40px 0;
    gap: 16px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }

    .request-btn {
      margin: 0;
    }

    .register-button {
      border: 1px solid $primary-blue;
    }
  }
}

.store-container {
  display: flex;

  @include media-breakpoint-down(sm) {
    padding: 64px 0;
  }

  .card-body {
    p {
      margin: 16px 0 32px 0;
    }
  }
}

.country-section {
  padding: 64px 0;
  background: $primary-white;

  .text {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 32px;

    p {
      line-height: normal;
    }
  }

  .country-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 0 64px;
    flex-wrap: wrap;

    @include media-breakpoint-up(xl) {
      padding: 0 217px;
    }

    .country-name {
      text-transform: uppercase;
      padding: 47.6px 31.7px;
      text-align: center;
      width: 155px;
      word-break: break-word;
      @include fontStyle($open-sans, $font-normal, 700, 16px, $light-grey);
    }

    .country-card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .button {
    color: $primary-blue;
  }

  @include media-breakpoint-down(md) {
    padding: 40px 0;

    .text {
      padding: 24px;
      gap: 24px;
    }

    .country-list {
      padding: 0 24px;
      gap: 32px;
      justify-content: space-evenly;
    }

    .country-card {
      width: 155px;
    }

    .btn-container {
      margin: 32px 0;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 62px 0;

    .btn-container {
      margin: 24px;
    }

    .text {
      padding: 24px 16px;
      gap: 16px;
    }

    .country-list {
      padding: 0 24px;

      .country-name {
        padding: 40px 24px;
        width: 155px;
      }
    }
  }

  .btn {
    color: $primary-blue;
  }
}

.login-section {
  padding: 64px calc((100vw - 1440px) / 2 + 120px);
  background: $primary-white;
  display: flex;
  gap: 32px;

  @include media-breakpoint-down(xl) {
    padding: 64px 120px;
  }

  @include media-breakpoint-down(md) {
    padding: 64px 24px;
  }

  @include media-breakpoint-down(sm) {
    padding: 64px 16px;
    flex-direction: column;
  }

  .request-access {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media-breakpoint-down(sm) {
      width: unset;
    }
  }

  .hide-button {
    display: none;
  }

  h4 {
    margin: 0;
    @include fontStyle($open-sans, $font-normal, 700, 16px, $primary-pink);
  }

  h3 {
    margin: 0;
    line-height: 105%;
    @include fontStyle($open-sans, $font-normal, 400, 40px, $primary-blue);
  }

  p {
    margin: 0;
    @include fontStyle($open-sans, $font-normal, 400, 15.864px, $light-grey);
  }

  .register-button {
    @include media-breakpoint-down(sm) {
      width: fit-content !important;
    }
  }
}

.request-btn {
  background: $primary-blue;
  color: $primary-white;
  margin: 40px 0;
}

.employee-login-section {
  display: flex;
  padding: 64px 120px;
  align-items: center;
  gap: 32px;
  opacity: 1;
  background-image: url('https://alcon.widen.net/content/upyi7xaykv/webp/SalesRep_DS.webp?crop=false&position=c&q=80&u=uxrrnh&w=1440&h=473');
  background-size: cover;

  @include media-breakpoint-down(md) {
    flex-direction: row;
    padding: 64px 24px;
    gap: 24px;
    background-image: url('https://alcon.widen.net/content/r1oajnwmes/webp/SalesRep_TB.webp?crop=false&position=c&q=80&u=uxrrnh&w=834&h=400');
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    background-image: url('https://alcon.widen.net/content/pvinspvwqh/webp/Sa…g?q=80&u=uxrrnh&w=375&h=630&position=c&crop=false');
  }

  @include media-breakpoint-up(md) {
    background-image: url('https://alcon.widen.net/content/upyi7xaykv/webp/SalesRep_DS.webp?crop=false&position=c&q=80&u=uxrrnh');
    background-size: 100% 100%;
  }

  @include media-breakpoint-up(xl) {
    height: 472px;
    padding: 64px 217px;
  }

  .content {
    padding: 0 24px 0 0;
    width: 662px;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 32px 24px;
    }

    h3 {
      margin-bottom: 16px;
      @include fontStyle($open-sans, $font-normal, 400, 40px, $primary-white);
    }

    p {
      margin-bottom: 40px;
      @include fontStyle($open-sans, $font-normal, 400, 18px, $off-white);
    }

    .register-button {
      max-width: none;
    }
  }

  .imageSection {
    margin-left: auto;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      padding: 16px;
    }

    .box {
      display: block;
      width: 463px;
      height: 347px;
      border-radius: 16px;

      @include media-breakpoint-down(md) {
        width: 381px;
        height: 341px;
      }

      @include media-breakpoint-down(sm) {
        width: 343px;
        height: 271px;
      }
    }
  }

  .register-button:active {
    color: $secondary-blue;
    border: 2px solid $primary-white;
    background: $primary-white;
  }
}

.update-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url('https://alcon.widen.net/content/efbthyzckw/webp/SurgicalTeaser_DSB.webp?position=c&quality=80&u=uxrrnh&use=ykrjk');
  height: 450px;
  padding: 0 calc((100vw - 1440px) / 2 + 120px);
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(xl) {
    padding: 0 120px;
    background-image: url('https://alcon.widen.net/content/efbthyzckw/webp/SurgicalTeaser_DSB.webp?position=c&quality=80&u=uxrrnh&use=ykrjk');
  }

  @include media-breakpoint-down(lg) {
    background-image: url('https://alcon.widen.net/content/rwalmb61ec/webp/SurgicalTeaser_DSS.webp?position=c&quality=80&u=uxrrnh&use=f6hrg');
  }

  @include media-breakpoint-down(md) {
    padding: 0 24px;
    background-image: url('https://alcon.widen.net/content/7sxhc7mtmq/webp/SurgicalTeaser_TB.webp?position=c&quality=80&u=uxrrnh&use=tu72v');
  }

  @include media-breakpoint-down(sm) {
    height: auto;
    padding: 96px 16px;
    background-image: url('https://alcon.widen.net/content/arjlwpbgwn/webp/SurgicalTeaser_MB.webp?position=c&quality=80&u=uxrrnh&use=khiid');
  }

  .content {
    display: flex;
    gap: 32px;
    flex-direction: column;
    width: 483px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    h4 {
      @include fontStyle($open-sans, $font-normal, 400, 40px, $primary-white);
      margin: 0;
      line-height: 105%;

      @include media-breakpoint-only(sm) {
        @include fontStyle($open-sans, $font-normal, 300, 40px, $primary-white);
      }
    }

    p {
      @include fontStyle($open-sans, $font-normal, 400, 18px, $primary-white);
      margin: 0;
      line-height: 130%;
    }
  }
}

adi-popup {
  .modal-dialog {
    max-width: none;
  }

  .modal-content {
    padding-bottom: 40px !important;
    width: 620px !important;

    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }

    .modal-title {
      color: $ace-neutral-800 !important;
    }

    .modal-body {
      margin: 0 !important;
    }
  }
}

.hide-button {
  display: none;
}